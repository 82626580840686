import { useMemo } from 'react'

export default function Logo(props: { size: number }) {
  // Generate a unique ID for the gradient
  const uniqueId = useMemo(
    () => `paint0_linear_${Math.random().toString(36).substring(2, 9)}`,
    []
  )

  return (
    <div className="p-[0.1px]">
      <svg
        width={props.size}
        height={props.size}
        viewBox="0 0 64 64"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="64" height="64" rx="16" fill={`url(#${uniqueId})`} />
        <path
          d="M32 23V15H24"
          stroke="white"
          strokeWidth="4.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M44 23H20C17.7909 23 16 24.7909 16 27V43C16 45.2091 17.7909 47 20 47H44C46.2091 47 48 45.2091 48 43V27C48 24.7909 46.2091 23 44 23Z"
          stroke="white"
          strokeWidth="4.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12 35H16"
          stroke="white"
          strokeWidth="4.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M48 35H52"
          stroke="white"
          strokeWidth="4.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M38 33V37"
          stroke="white"
          strokeWidth="4.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M26 33V37"
          stroke="white"
          strokeWidth="4.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <defs>
          <linearGradient
            id={uniqueId}
            x1="0"
            y1="64"
            x2="64"
            y2="0"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#6D28D9" />
            <stop offset="1" stopColor="#0EA5E9" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  )
}
