import { AnimatePresence, motion } from 'framer-motion'
import { AlignJustify, ArrowRight, Gauge, XIcon } from 'lucide-react'
import { useEffect, useState } from 'react'
import { cn } from '@/lib/utils'
import Logo from '../../assets/Logo'
import { Button } from '../ui/button'
import { menuLinks } from '@/config/site'
import ChatbotRequestModal from '../chatbot-request'

export default function Header() {
  const mobilenavbarVariant = {
    initial: {
      opacity: 0,
      scale: 1
    },
    animate: {
      scale: 1,
      opacity: 1,
      transition: {
        duration: 0.2,
        ease: 'easeOut'
      }
    },
    exit: {
      opacity: 0,
      transition: {
        duration: 0.2,
        delay: 0.2,
        ease: 'easeOut'
      }
    }
  }

  const mobileLinkVar = {
    initial: {
      y: '-20px',
      opacity: 0
    },
    open: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.3,
        ease: 'easeOut'
      }
    }
  }

  const containerVariants = {
    open: {
      transition: {
        staggerChildren: 0.06
      }
    }
  }

  const [hamburgerMenuIsOpen, setHamburgerMenuIsOpen] = useState(false)

  useEffect(() => {
    const html = document.querySelector('html')
    if (html) html.classList.toggle('overflow-hidden', hamburgerMenuIsOpen)
  }, [hamburgerMenuIsOpen])

  useEffect(() => {
    const closeHamburgerNavigation = () => setHamburgerMenuIsOpen(false)
    window.addEventListener('orientationchange', closeHamburgerNavigation)
    window.addEventListener('resize', closeHamburgerNavigation)

    return () => {
      window.removeEventListener('orientationchange', closeHamburgerNavigation)
      window.removeEventListener('resize', closeHamburgerNavigation)
    }
  }, [setHamburgerMenuIsOpen])

  return (
    <>
      <ChatbotRequestModal>
        <header
          className={cn(
            'fixed left-0 top-0 z-50 w-screen border-b backdrop-blur-md',
            window.location.pathname === '/' &&
              'animate-fade-in -translate-y-4 opacity-[0.01] [--animation-delay:600ms]'
          )}>
          <div className="container flex h-14 items-center justify-between">
            <div className="flex gap-2 md:gap-4 items-center">
              <a href="/" className="text-md gap-3 flex items-center">
                <Logo size={36} />
                <span className="font-bold inline-block text-xl font-heading text-slate-800">
                  LeoAI
                </span>
              </a>

              <div className="gap-0 hidden md:flex ml-4 text-slate-600">
                {menuLinks.map(item =>
                  item.label === 'Demó' ? (
                    <span
                      key={item.label}
                      id="demó"
                      className="hover:text-blue-700 px-4 font-medium cursor-pointer">
                      Demó
                    </span>
                  ) : (
                    <a
                      key={item.label}
                      href={item.href}
                      className="hover:text-blue-700 px-4 font-medium">
                      {item.label}
                    </a>
                  )
                )}
              </div>
            </div>

            <div className="ml-auto h-full items-center gap-2 hidden sm:flex">
              <button
                onClick={() => {
                  const button = document.querySelector(
                    '#hero-cta-button'
                  ) as HTMLButtonElement
                  button?.click()

                  document.cookie = 'chatbot-request-from=header'
                }}
                className="text-white w-full rounded-lg bg-gradient-to-r from-indigo-500 to-sky-500 tracking-tighter flex gap-2 duration-150 items-center justify-center hover:mb-1 font-normal py-[6px] px-5">
                Ingyenes demó
                <ArrowRight className="ml-1" size={20} />
              </button>
            </div>

            <button
              className="ml-6 md:hidden"
              onClick={() => setHamburgerMenuIsOpen(open => !open)}>
              <span className="sr-only">Menü kinyitása</span>
              {hamburgerMenuIsOpen ? <XIcon /> : <AlignJustify />}
            </button>
          </div>
        </header>
        <AnimatePresence>
          <motion.nav
            initial="initial"
            exit="exit"
            variants={mobilenavbarVariant}
            animate={hamburgerMenuIsOpen ? 'animate' : 'exit'}
            className={cn(
              `bg-background/70 fixed left-0 top-0 z-50 h-screen w-screen overflow-auto backdrop-blur-md `,
              {
                'pointer-events-none': !hamburgerMenuIsOpen
              },
              'flex flex-col justify-between'
            )}>
            <div>
              <div className="container flex h-14 items-center justify-between">
                <a href="/" className="text-md gap-3 flex items-center">
                  <Logo size={28} />
                  <span className="inline-block text-lg font-semibold text-slate-800">
                    LeoAI
                  </span>
                </a>

                <button
                  className="ml-6 md:hidden"
                  onClick={() => setHamburgerMenuIsOpen(open => !open)}>
                  <span className="sr-only">Menü bezárása</span>
                  {hamburgerMenuIsOpen ? <XIcon /> : <AlignJustify />}
                </button>
              </div>
              <motion.ul
                className="flex flex-col uppercase ease-in md:flex-row md:items-center md:normal-case"
                variants={containerVariants}
                initial="initial"
                animate={hamburgerMenuIsOpen ? 'open' : 'exit'}>
                {menuLinks.map(item => (
                  <motion.li
                    key={item.label}
                    variants={mobileLinkVar}
                    className="border-grey-dark w-full border-b py-0.5 md:border-none">
                    {item.label === 'Demó' ? (
                      <span
                        id="demó-2"
                        className={`hover:text-grey flex justify-center h-[var(--navigation-height)] w-full items-center text-xl transition-[color,transform] duration-300 md:translate-y-0 md:text-sm md:transition-colors cursor-pointer ${
                          hamburgerMenuIsOpen ? '[&_span]:translate-y-0' : ''
                        }`}>
                        Demó
                      </span>
                    ) : (
                      <a
                        className={`hover:text-grey flex justify-center h-[var(--navigation-height)] w-full items-center text-xl transition-[color,transform] duration-300 md:translate-y-0 md:text-sm md:transition-colors ${
                          hamburgerMenuIsOpen ? '[&_a]:translate-y-0' : ''
                        }`}
                        href={item.href}>
                        {item.label}
                      </a>
                    )}
                  </motion.li>
                ))}

                <motion.div
                  className={cn(
                    'transition-all duration-300 delay-200 px-4 mt-12 justify-end items-end gap-2 flex sm:hidden -translate-y-10 opacity-[0.01]',
                    hamburgerMenuIsOpen ? 'translate-y-0 opacity-100' : ''
                  )}>
                  <button
                    onClick={() => {
                      const button = document.querySelector(
                        '#hero-cta-button'
                      ) as HTMLButtonElement
                      button?.click()

                      document.cookie = 'chatbot-request-from=header'
                    }}
                    className="text-white w-full rounded-lg bg-gradient-to-r from-indigo-500 to-sky-500 tracking-tighter flex gap-2 items-center justify-center hover:mb-1 font-medium py-[6px] px-3">
                    Ingyenes demó
                    <ArrowRight className="ml-1" size={20} />
                  </button>
                </motion.div>
              </motion.ul>
            </div>
          </motion.nav>
        </AnimatePresence>
      </ChatbotRequestModal>
    </>
  )
}
